.adv_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adv_item__circle_outer {
    width: 143px;
    height: 143px;
    background-color: #fff;
    background-color: #13161d;
    border-radius: 50%;
    position: relative;
    margin-bottom: 20px;
}

.adv_item__circle_inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95px;
    height: 95px;
    border-radius: 47px 48px 48px 47px;
    background-color: #fff607;
    box-shadow: 0 13px 32px rgba(255,234,0,.25);
    background-image: linear-gradient(to top, #ffae00, #ffe555);

}

.adv_item__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.adv_item__title {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 14px;
}

.adv_item__desc {
    font-weight: 300;
    color: #fff;
    text-align: center;
}