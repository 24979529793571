.works_section {
    padding-bottom: 140px;
}

.works_section__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.works_section__items > div {
    margin-bottom: 20px;
}

.works_section__sub {
    color: #fff;
    text-align: center;
    display: block;
}

.works_section__title {
    margin-bottom: 50px;
}

.works_section__sub {
    margin-bottom: 100px;
}