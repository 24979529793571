.accordion-wrapper .accordion-item h3.accordion-title {
  font-size: 1.75rem;
  margin: 0;
}

.accordion-item {
  border-top: 1px solid #4a4c4f;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 1rem;
  border: none;
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 1rem;
}

.accordion-wrapper .accordion-item h3.accordion-title button.active {
  background-color: transparent;
  color: #ffffff;
  border: transparent solid 1px;
}

.accordion-wrapper .accordion-item h3.accordion-title button:hover {
    background-color: transparent;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.title-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
  width: 10%;
  display: flex;
  justify-content: center;
}


.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.minus {
  width: 24px;
  height: 24px;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.plus {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close { 
  background: #e0e0e0;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity:0;
  width:100%;
  height:0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  background: transparent;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity:1;
  width:100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
  font-weight: 300;
font-size: 18px;
color: #fff;
line-height: 25px;
}

.title-wrapper {
  cursor: pointer;
}

.accordion-wrapper .accordion-item .accordion-panel p {
  margin: 0;
}

