.work_item {
    width: 100%;
    max-width: 300px;
}

.work_item__icon {
    margin-bottom: 10px;
}

.work_item__title {
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
}

.work_item__text {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}