.desc_section {
    padding-bottom: 230px;
}

.desc_section__title {
    margin-bottom: 50px;
}

.desc_section__text {
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    text-align: center;
    max-width: 700px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}