.sell_page__images_line img {
    width: 66px;
    margin-right: 15px;
}

.sell_page {
    padding-top: 80px;
}

.sell_page__account_info {
    display: flex;
    padding: 0 90px;
    margin-bottom: 210px;
}



.sell_page__main_image_wrap {
    margin-bottom: 20px;
}

.sell_page__main_image_wrap img {
    max-width: 500px;
    width: 100%;
}

.sell_page__images_line {
    display: flex;
}

.im_wrap {
    width: 80px;
    height: 80px;
    margin-right: 14px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: .3s;
    position: relative;
    overflow: hidden;
}

.im_wrap_active {
    border: 2px solid #ffd800;
}

.im_wrap img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    position: absolute;
    width: 110%;
    height: 110%;
}

.sell_btn {
    width: 220px
}

.sell_page__info span {
    color: #fff;
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
}

.sell_page__account_name {
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    margin-bottom: 32px;
    display: block;
}

.sell_page__account_price {
    font-weight: 300;
    font-size: 24px;
    color: #fff607;
    display: block;
    margin-bottom: 48px;
}

.sell_page__account__bill {
    padding-left: 90px;
}

.sell_page__desc {
    font-weight: 300;
font-size: 18px;
color: #fff;
line-height: 25px;
margin-top: 128px;
}

.sell_page__info {
    margin-top: 380px;
    padding-left: 30px;
}

.sell_page__info h4 {
    font-weight: 700;
font-size: 18px;
color: #fff607;
margin-bottom: 32px;
}

.sell_page__info ul li {
    font-weight: 300;
font-size: 18px;
color: #fff;
}

.link {
    text-decoration: none;
}

@media screen and (max-width: 980px) {
    .sell_page__account_info {
        flex-direction: column;
        padding: 0;
    }

    .sell_page {
        padding-top: 0;
    }

    .sell_page__account__bill {
        padding-left: 0;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .sell_page__info {
        margin-top: 0;
        order: 2;
        margin-bottom: 40px;
    }

    .sell_page__account_price {
        margin: 0;
        order: 4;
        margin-bottom: 30px;
    }

    .sell_btn {
        order: 5;
    }
}