.boost_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boost_section__container {
    display: flex;
    justify-content: space-around;
    position: relative;
    transform: translateY(-50%);
    flex-wrap: wrap;
    padding: 0 150px;
}

.boost_section__container a {
    margin-bottom: 20px;
}


@media screen and (max-width: 810px) {
    .boost_section__container {
        transform: translateY(-15%);
        padding: 0;
    }
}