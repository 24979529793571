.button {
    display: block;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    max-width: 280px;
    height: 70px;
    border-radius: 35px;
    border: 0;
    background-color: #ffd800;
    box-shadow: 10px 9px 32px rgba(255,234,0,.25);
    background-image: linear-gradient(to top, #ffd200, #fff607);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.button img {
    margin-right: 10px;
}

.button_white {
    background: transparent;
    color: #fff;
    width: 280px;
    height: 70px;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}