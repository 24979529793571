.footer {

}

.footer__container {
    padding-top: 48px;
    border-top: 1px solid #4a4c4f;
}

.footer__line {
    display: flex;
    justify-content: space-between;
}

.footer__nav {
    display: flex;
    margin-bottom: 45px;
    margin-top: 40px;
    justify-content: flex-end;
}

@media screen and (max-width: 460px) {
    .footer__nav {
        display: none;
    }
}

.footer__payment {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.footer__trust {
    margin-right: 20px;

}

.footer__trust img {
    width: 125px !important;
    max-width: none !important;
}

.footer__payment img {
    max-width: 70px;
    margin-left: 10px;
}

.footer__nav_link {
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    position: relative;
    margin-right: 15px;
}

.footer__nav_link:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    left: -7px;
    top: 4px;

}

.footer__nav_link:first-child:after {
    display: none;
}

.footer__info {
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
}

.footer__info a {
    text-decoration: none;
    color: #fff;
}

.footer__social_media {
    display: flex;
    justify-content: flex-end;
}