.col {
    width: 25%;
}

@media screen and (max-width: 1228px) {
    .col {
        width: 50%;
        margin-top: 20px;
    }

    .col > * {
        margin: 0 auto;
    }
}

@media screen and (max-width: 612px) {
    .col {
        width: 100%;
    }
}