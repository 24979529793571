.modal {
    background-color: #15181f;
    position: fixed;
    padding: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 100%
}

.modal__title {
    font-size: 36px;
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
}

.contacts_section__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}


.modal__check {
    color: #fff;
    display: flex;
    align-items: center;
}

.modal__check a {
    margin-left: 40px;
}

.modal__check_order {
    display: block;
    margin-top: 20px;
    color: #ffd800;
    font-size: 18px;
    font-weight: 500;
    max-width: 300px;
}

.modal__check h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.captcha {
    color: #fff;
}


.contacts_section__input {
    font-weight: 400;
    margin-bottom: 30px;
    background-color: #1a1e28;
    width: 100%;
    max-width: 600px;
    height: 40px;
    color: #fff;
    border: 1px solid #2d3343;
    padding-left: 15px;
    border-radius: 4px;
}

.contacts_section__send {
    display: block;
    margin-top: 25px;
    text-align: center;
    font-family: 'Proxima Nova';
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    background: transparent;
    color: #fff;
    width: 280px;
    height: 70px;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    font-size: 16px;
}

.modal_wrap {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.modal__close {
    position: absolute;
    right: 10px;
    top: 20px;
}

.modal__close span {
    width: 20px;
    height: 2px;
    border-radius: 10px;
    display: block;
    background-color: #fff;
}

.modal__close span:first-child {
    transform: rotate(45deg) translateY(1px);
}

.modal__close span:last-child {
    transform: rotate(-45deg) translateY(-2px);
}