
.results_section {
    padding-bottom: 180px;
}

.results_section__container {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    flex-wrap: wrap;
}

.results_section__container > a {
    margin-bottom: 20px;
}