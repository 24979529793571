.mmboostpage {
    padding-top: 130px;
    padding-bottom: 90px;
}

.mmboostpage__calculator {
    background-color: #15181f;
    padding: 85px;
}

.mmboostpage__calculator h3 {
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 62px;
}

.mmboostpage__button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media screen and (max-width: 642px) {
    .mmboostpage__calculator {
        padding: 20px;
    }
}