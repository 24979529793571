.boost_card {
    width: 100%;
    max-width: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #15181f;
    position: relative;
    height: 246px;
    overflow: hidden;
}

.boost_card img {
    position: absolute;
    width: 100%;
}

.boost_card:hover > .boost_card__name {
    color: #000;
}

.boost_card__name {
    position: absolute;
    top: 30px;
    left: 20px;
    color: #fff;
    font-size: 18px;
}

@media screen and (max-width: 418px) {
    .boost_card img {
        width: 80%;
    }
}

