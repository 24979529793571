.accounts_page__desc {
    margin-bottom: 136px;
}

.accounts_page__sell {
    background-color: #15181f;
    padding: 0 65px;
    padding-bottom: 52px;
    margin-bottom: 60px;
}

.accounts_page__sell_title {
    font-weight: 300;
    font-size: 46px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding-top: 100px;
    margin-bottom: 45px;
}

.accounts_page__sell a {
    margin: 0 auto;
    margin-bottom: 56px;
}

.accounts_page__sell ul {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.accounts_page__sell ul li {
    color: #fff;
    font-family: 'Proxima Nova';
    font-weight: 400;
}

.accounts_page__offers {
    padding-bottom: 94px;
}

.accounts_page__offers h5 {
    font-weight: 700;
    font-size: 18px;
    color: #fff607;
    text-align: center;
    margin-bottom: 30px;
}

.accounts_page__offers p {
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}