.social_media {

}

.social_media__link {
    margin-left: 10px;
}

.social_media__icon {
    width: 35px;
    height: 35px;
}