.adv_section {
    padding-top: 100px;
}

.adv_section__desc {
    display: block;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    font-weight: 300;
}

.adv_section__desc_wrap {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 200px;
}

.adv_section__desc:not(:last-child) {
    margin-bottom: 40px;
    
}

.adv_section__advantages {
    display: flex;
    justify-content: space-around;
    margin-bottom: 160px;
    flex-wrap: wrap;
}

@media screen and (max-width: 504px) {
    .adv_section__advantages > div {
        margin-bottom: 20px;
    }
}