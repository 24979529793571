.content_article {
    display: grid;
    grid-template-rows: auto;
    gap: 24px;
    padding: 0 10px;
}

.content_article h2 {
    margin-top: 10px;
    color: #fff;
}

.content_article p {
    color: #fff;
}

.content_article p:last-child {
    margin-bottom: 20px;
}
