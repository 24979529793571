.account_card {
    display: block;
    max-width: 282px;
    width: 100%;
    background-color: #15181f;
    margin-bottom: 30px;
    text-decoration: none;
    min-height: 385px;
}

.account_card__image_wrap {
    width: 100%;
    height: 160px;
    overflow: hidden;
    margin-bottom: 30px;
}

.account_card__image {
    object-fit: cover;
    width: 100%;
    height: 160px;
}

.account_card__info {
    padding: 0 26px;
    padding-bottom: 10px;
}

.account_card__benefits {
    list-style: none;
    margin-bottom: 30px;
}

.account_card__benefits li {
    color: #fff;
    margin-bottom: 10px;
}

.account_card__benefits li:first-child {
    margin-bottom: 15px;
}

.account_card__price {
    color: #fff607;
}