.checkbox {
    
}

.checkbox__hidden {
    display: none;
}

.checkbox__hidden:checked + .checkbox__custom {
    background-color: #fff607;
}

.checkbox__hidden:checked + .checkbox__custom > .cheсkbox__controller {
    left: 24px;
}

.checkbox__custom {
    border: 1px solid #2c3242;
    width: 55px;
    height: 30px;
    position: relative;
    border-radius: 20px;
    transition: .3s;
    cursor: pointer;
}

.cheсkbox__controller {
    transition: .3s;
    position: absolute;
    width: 27px;
    height: 27px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: #fff;
    transition: .5s;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
}