.contacts_section {
    padding-bottom: 96px;
}

.contacts_section__content {
    display: flex;
    flex-wrap: wrap;
}

.contacts_section__col {
    width: 50%;
}

.contacts_section__sub {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    color: #fff;
}

.content_card {
    transition: transform 0.7s ease 0s, opacity 0.7s ease 0s;
}

.content_card:hover {
    transform: translateY(-8px);
    opacity: 0.7;
}

.content_link {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.content_title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
}

.content_text {
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    word-wrap: break-word;
}

@media (max-width: 767px) {
    .content_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 24px;
        width: 100%;
    }

    .content_img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 10px;
    }
}

@media (max-width: 840px) {
    .contacts_section__col {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .content_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 24px;
        padding: 0 30px;
    }

    .content_img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 10px;
    }
}

@media (min-width: 1000px) {
    .content_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 24px;
        padding: 0 30px;
    }

    .content_img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 10px;
    }
}





