.header {
    background: url('bg-header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 295px;
}

.header__mini {
    padding-bottom: 0;
    background: none;
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
}

.header__title {
    font-size: 81px;
    line-height: 65px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    margin-top: 50px;
    margin-bottom: 30px;

}

.header__title span {
    color: #fff607;
}

.header__desc {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.header__ru {
    margin-top: 30px;
    display: block;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.header__ru img {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}



@media screen and (max-width: 910px) {

    .header {
        padding-top: 20px;
    }

    .header__title {
        font-size: 54px;
        line-height: 54px;
    }
}

@media screen and (max-width: 810px) {
    .header {
        padding-bottom: 175px;
    }
}

@media screen and (max-width: 624px) {
    .header__title {
        font-size: 28px;
        line-height: 28px;
    }
}