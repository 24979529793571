.account_container {
    padding-top: 35px;
    border-top: 2px solid #4a4c4f;
    padding-bottom: 76px;
}

.account_container__title {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    margin-bottom: 78px;
}