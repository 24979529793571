* {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 0;
    font-family: 'Proxima Nova', sans-serif;
}

body {
    background-color: #0e1014;
}