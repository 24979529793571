.nav__link {
    color: #fff;
    text-transform: uppercase;
    font-size: 17px;
    text-decoration: none;
}

.nav__link:hover {
    opacity: .7;
}

.nav__link:not(:first-child) {
    margin-left: 30px;
}

@media screen and (max-width: 890px) {
    .nav__link {
        display: block;
    }

    .nav__link:not(:first-child) {
        margin-left: 0;
        margin-top: 10px;
    }
}