.result_item {
    width: 100%;
    max-width: 280px;
    height: 250px;
    background-color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
}

.result_item:hover {
    background-color: #fff607;
}

.result_item__image_wrap {
    width: 100%;
    height: 180px;
    position: relative;

}

.result_item__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.result_item__text {
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: #000;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}