.calc_desc {
    padding-top: 80px;
}

.calc_desc__title {
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.calc_desc__text {
    text-align: center;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin-bottom: 140px;
    max-width: 700px;
    margin: 0 auto;
}