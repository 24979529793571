
.accordion_section {
    padding-bottom: 180px;
}

.accordion_section__title {
    margin: 0 auto;
    margin-bottom: 100px;
    width: 60%;
}

@media screen and (max-width: 700px) {
    .accordion_section__title {
        width: 90%;
    }   
    
}