.title {
    color: #fff;
    font-weight: 700;
    font-size: 46px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

.title__low {
    text-transform: none;
}

@media screen and (max-width: 700px) {
    .title {
        font-size: 32px;
    }
}