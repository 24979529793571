.rc-slider-handle {
    background-color: #E8E7E6;
    border: none;
    width: 20px;
    height: 20px;
    margin-top: -9px;
}

.rc-slider-track {
    background-color: #fff607;
}

.rc-slider-rail {
    background-color: #e9e9e9;
}
