.contacts_section {
    padding-bottom: 96px;
}

.contacts_section__content {
    display: flex;
    flex-wrap: wrap;
}

.contacts_section__touch {
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

.contacts_section__desc {
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-bottom: 56px;
}

.contacts_section__link {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-decoration: none;
}

.contacts_section__link_image_wrap {
    width: 72px;
    height: 72px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
    background-color: #1a1e28;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts_section__link_text {
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-left: 15px;
}

.contacts_section__col {
    width: 50%;
}

@media screen and (max-width: 840px) {
    .contacts_section__col {
        width: 100%;
    }
}

.contacts_section__col form {
    margin-top: 30px;
}

.contacts_section__title {
    margin-bottom: 45px;
}

.contacts_section__sub {
    text-align: center;
    color: #fff;
    display: block;
    margin-bottom: 112px;
}

.contacts_section__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}


.contacts_section__input {
    font-weight: 400;
    margin-bottom: 30px;
    background-color: #1a1e28;
    width: 100%;
    max-width: 600px;
    height: 40px;
    color: #fff;
    border: 1px solid #2d3343;
    padding-left: 15px;
    border-radius: 4px;
}

.trust {
    display: block;
    margin-top: 60px;
}

.trust img {
    max-width: 250px;
}

.contacts_section__send {
    display: block;
    margin-top: 25px;
    text-align: center;
    font-family: 'Proxima Nova';
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    background: transparent;
    color: #fff;
    width: 280px;
    height: 70px;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    font-size: 16px;
}

.contacts_section__foot {
    text-align: center;
    color: #fff;
    margin-top: 100px;
}

.contacts_section__foot a {
    color: #fff607;
}

.captcha {
    color: red;
}