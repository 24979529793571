.info_block {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.info_block__title {
    font-weight: 700;
    font-size: 18px;
    color: #fff607;
    margin-bottom: 8px;
}

.info_block__text {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 65px;
    line-height: 25px;
}